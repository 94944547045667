<template>
  <div class="companyMember">
    <div class="title clearfix">
      <div class="fl name">{{$t('enterpriseMem')}}</div>
      <div
        class="fr exchange mainColor cursor"
        @click="exchange"
        v-show="count > 3"
      >
        <i class="el-icon-refresh"></i>{{$t('changeBatch')}}
      </div>
    </div>
    <div class="memberData">
      <div v-for="(item, index) in memberList" :key="index" class="member">
        <div class="detail clearfix">
          <div class="logo fl">
            <el-image
             :alt="$t('siffa')"
              @click="showCard(item)"
              :src="item.avatar ? item.avatar : defaultAvatar"
              class="img cursor"
            ></el-image>
          </div>
          <div class="fl">
            <div class="company clearfix">
              <div
                class="fl name textOverflow"
                :title="item.name_en | priorFormat(item.name_zh, 'en')"
              >
                {{ item.name_en | priorFormat(item.name_zh, "en") }}
              </div>
              <div
                class="job textOverflow"
                :title="
                  item.job_title_en | priorFormat(item.job_title_zh, 'en')
                "
              >
                {{ item.job_title_en | priorFormat(item.job_title_zh, "en") }}
              </div>
            </div>
          </div>
          <!-- <div class="fr" v-show="item.company_id != USER_INFO.company_id">
            <el-image
              class="likeimg cursor"
              :src="item.siffa_collection_id ? like : nolike"
              @click="likeChange(item, index)"
            ></el-image>
            收藏
          </div> -->
          <div
            class="textOverflow"
            style="padding: 5px 0 10px 15px; width: 170px"
          >
            {{ item.business_advantage | lineFormat }}
          </div>
          <!-- <div class="tc">
            <el-button
              type="danger"
              plain
              size="mini"
              @click="openDialog(item)"
              :disabled="item.company_id == USER_INFO.company_id"
              >{{$t('reserveMeeting')}}</el-button
            >
          </div> -->
        </div>
      </div>
    </div>
    <noDataImg v-if="!loading && memberList.length == 0"></noDataImg>
    <orderFreeDialog
      ref="orderAgenda"
      :orderVisible="orderVisible"
      @dataChange="confimOrder"
      @orderVisibleShow="orderVisibleShow"
    ></orderFreeDialog>
  </div>
</template>
<script>
import orderFreeDialog from "~/baseComponents/orderFreeDialog";
export default {
  props: {
    company_id: {
      type: String | Number,
      default: "",
    },
  },
  components: {
    orderFreeDialog,
  },
  data() {
    return {
      memberList: [],
      loading: false,
      currentPage: 1,
      count: 0,
      currentLike: false,
      orderVisible: false,
      currentOrder: {},
      defaultAvatar:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      nolike: require("~/baseAssets/nolike.png"),
      like: require("~/baseAssets/like.png"),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    showCard(e) {
      console.log("---", e);
      let row = e;
      this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
    },
    async getList() {
      this.loading = true;
      let params = {
        company_id: this.company_id,
        user_id: this.USER_INFO.id,
        start: this.currentPage - 1,
        limit: 3,
        type: 0,
      };
      let res = await this.$store.dispatch(
        "API_company/getAccountList",
        params
      );
      if (res.success) {
        this.loading = false;
        this.memberList = res.data;
        this.count = res.count;
        if (res.count / 3 <= this.currentPage) {
          this.currentPage = 0;
        }
      } else {
        this.loading = false;
      }
    },
    exchange() {
      if (this.count > 3) {
        this.currentPage += 1;
        this.getList();
      }
    },
    orderVisibleShow(val) {
      if (val) {
        this.orderVisible = true;
      } else {
        this.orderVisible = false;
      }
    },
    openDialog(item) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (
        this.USER_INFO.company_bind_status == 0 ||
        !this.USER_INFO.company_id
      ) {
        //没有绑定公司
        this.$message.warning(this.$t("company_information"));
        return;
      }

      this.orderVisible = true;
      this.currentOrder = item;
      this.$refs.orderAgenda.hide();
    },
    async likeChange(val, likeindex) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      let params = {
        related_id: val.user_id,
        user_id: this.USER_INFO.id,
        collection_type: 2,
        source: this.PJSource,
      };
      if (val.siffa_collection_id) {
        params.siffa_collection_id = val.siffa_collection_id;
        params.deleted = 1;
      }
      let res = await this.$store.dispatch(
        "API_company/favoritesCompany",
        params
      );
      if (res.success) {
        this.memberList.forEach((it, index) => {
          if (index == likeindex) {
            if (val.siffa_collection_id) {
              it.siffa_collection_id = 0;
            } else {
              it.siffa_collection_id = res.data;
            }
          }
        });
        this.currentLike = !this.currentLike;
      }
    },
    // 点击预约
    async confimOrder(val) {
      let _params = {
        send_company_id: this.USER_INFO.company_id,
        send_user_id: this.USER_INFO.id,
        in_user_id: this.currentOrder.user_id,
        in_company_id: this.currentOrder.company_id,
        daily_source: 2,
        related_id: this.USER_INFO.id,
        source: this.PJSource,
      };
      let params = Object.assign(_params, val);
      let res = await this.$store.dispatch("API_company/companyInvite", params);
      this.orderVisible = false;
      if (res.success) {
        this.$message.success(this.$t("sendSuccess"));
      } else if (res.errorcode == 1000) {
        this.$message.warning(this.$t("repeatTheBooking"));
      } else if (res.errorcode == 1017) {
        this.$message.warning(this.$t("companyCatalog1v1Error2"));
      } else if (res.errorcode == 1018) {
        this.$message.warning(this.$t("companyCatalog1v1Error3"));
      } else if (!params.send_company_id) {
        this.$message.warning("请先完善公司信息");
        // console.log("暂无send_user_id，send_company_id，related_id，无法预约");
      } else {
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.companyMember {
  width: 100%;
  height: 360px;
  background: #ffffff;
  padding: 16px;
  position: relative;
  .title {
    margin-bottom: 16px;
    .name {
      font-weight: 600;
    }
  }
  .memberData {
    .member {
      height: 100px;
    }
    .detail {
      .img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
      }
      .company {
        width: 110px;
        margin-left: 15px;
        .name {
          max-width: 60px;
          margin-right: 10px;
        }
        .job {
          max-width: 50px;
          color: #909399;
        }
      }
      .likeimg {
        width: 14px;
        height: 12px;
        // margin: 5px 5px;
      }
    }
  }
}
.el-button--danger.is-plain {
  background: #fff;
  border-color: #7a7c96;
  color: #1D2388;
}

.el-button--danger.is-plain:focus,
.el-button--danger.is-plain:hover {
  background: #fff;
  border-color: #1D2388;
  color: #1D2388;
}
</style>